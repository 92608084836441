<template>
  <div style="width: 100%;height: 100%">
    <div :class="mode == '1' ? 'mylogo' : 'header-default'">
      <div class="col-sm-4">
        <Logo />
      </div>
      <div class="col-sm-4">
        <b class="test_name"> Speaking (Task {{ question_detail.chapter_order }}) </b>
      </div>
      <div class="col-sm-4">
        <el-button @click="setPage" type="primary">
          Continue
        </el-button>
      </div>
    </div>
    <div class="listening-passage row mar">
      <div class="intro_screen cover text-center">
        <audio
          id="reading_time"
          :src="question_detail.content.reading_audio"
          @ended="readPassage"
          autoplay
        ></audio>
        <h3 class="reading_time text-center">
          Reading Time:
          <el-button type="danger" size="small">
            <b class="time_text">{{ time.minute }} : {{ time.second }}</b>
          </el-button>
        </h3>
        <hr />
        <p
          v-if="showPassage"
          class="reading_time_text text-left"
          v-html="question_detail.content.reading"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/views/toefl/toeflTest/components/Logo";

export default {
  components: {
    Logo
  },

  mixins: [],

  props: ["question_detail", "mode"],
  data() {
    return {
      imgUrl:
        "https://ivy-way.s3-ap-northeast-1.amazonaws.com/toefl/img/headset.jpeg",
      showPlay: true,
      showPassage: false,
      time: {
        minute: "00",
        second: "45"
      },
      timer: {}
    };
  },
  computed: {},
  watch: {},

  created() {
    if (!this.question_detail.content.reading_audio) {
      this.readPassage();
    }
  },

  methods: {
    readPassage() {
      this.showPassage = true;
      this.countDown(45);
    },
    countDown(seconds_remaining) {
      let vm = this;
      vm.timer = setInterval(() => {
        let minute;
        let second;
        minute = Math.floor(seconds_remaining / 60);
        second = Math.floor(seconds_remaining) - minute * 60;
        // 让个位数的时间正常显示
        if (minute <= 9) minute = "0" + minute;
        if (second <= 9) second = "0" + second;

        this.time.minute = minute;
        this.time.second = second;

        seconds_remaining--;
        if (seconds_remaining == 0) {
          clearInterval(this.timer);
          vm.$parent.setPage();
        }
      }, 1000);
    },
    setPage() {
      clearInterval(this.timer);
      this.$parent.setPage();
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.intro_screen {
  width: 100%;
}
.time-container {
  margin: 0 auto;
}
.time_text {
  font-size: 16px;
}
</style>
