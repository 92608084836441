<template>
  <div id="QuestionDefault" class="col-xs-12 col-sm-6 col-md-6 cover">
    <div class="intro_screen question cover" style="opacity: 1;">
      <div class="reading_default">
        <h3 class="q_title" v-html="question_detail.question"></h3>
        <div class="options">
          <div
            class="radio"
            v-for="option in question_detail.options"
            :key="option.id"
          >
            <div>
              <input
                class="magic-radio"
                :value="option.id"
                v-model="theAnswer"
                type="radio"
                :id="option.id"
              />
              <label :for="option.id">{{ option.title }}</label>
            </div>
          </div>
        </div>
        <h5>The corresponding paragraph number is highlighted.</h5>
        <GoogleAd :key="question_detail.id" />
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import GoogleAd from "@/views/ad/GoogleAd";

export default {
  props:["question_detail", "answers"],
  created(){
  },
  components: {
    GoogleAd
  },
  data() {
    //不能直接修改answer
    return {
      theAnswer: this.answers[0]
    };
  },
  mounted(){
    this.getAnswer();
  },
  watch: {
    //监听answer进行改变
    answers(value) {
      this.theAnswer = value[0];
    },
    //你的答案
    theAnswer() {
      this.getAnswer();
    }
  },
  methods: {
    getAnswer(){
      let answers = [];
      answers.push(this.theAnswer);
      answers.sort(this.cmp);
      this.$parent.setAnswers(answers);
    },
    cmp(a, b){
      return a - b;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.cover {
  height: 100%;
}
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0 15px;
}
</style>
