<template>
  <div class="text-center">
    <el-card class="box-card">
      <span class="result-icon text-success">
        <i class="fas fa-check-circle"></i>
      </span>
      <p class="result-title">
        {{ $t("toefl.testCompleted") }}
      </p>
      <div class="row" v-if="CompanyName === 'TestAdmit'">
        <p class="result-title col-sm-12" v-html="$t('toefl.shareText')" />
        <div class="col-sm-12">
          <iframe
            src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Ftoeflmocks&width=450&layout=standard&action=like&size=large&share=true&height=35&appId"
            width="370"
            height="35"
            style="border:none;overflow:hidden"
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      </div>
      <hr style="margin: 20px" />
      <p class="result-title">
        {{ $t("toefl.clickToViewTestResult") }}
      </p>
      <el-button type="success" size="small" @click="$emit('toTranscript')">
        {{ $t("toefl.viewTestResult") }}
      </el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: ["mode"],
  data() {
    return {
      showQuestion: false,
      page: 1,
      count: 0
    };
  },
  computed: {},
  watch: {},

  created() {},
  methods: {}
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.fb-like.fb_iframe_widget {
  bottom: 5px;
}
.result-icon {
  font-size: 50px;
  margin-bottom: 20px;
}
.result-title {
  font-size: 14px;
  color: #5e6d82;
  line-height: 35px;
}
</style>
