<template>
  <div>
    <div v-if="mode == '1'" class="mylogo">
      <div class="col-sm-4">
        <Logo />
      </div>
      <div class="col-sm-4">
        <button
          type="primary"
          @click="$emit('dook', 'next')"
          :disabled="disableNextButton"
        >
          Next
        </button>
      </div>
      <div class="col-sm-4">
        <div class="time">
          <button @click="timeShow = !timeShow">
            <b v-show="timeShow">Hide</b>
            <b v-show="!timeShow">Show</b>
          </button>
          <button type="danger">
            <span class="time_show" v-show="timeShow">
              <b>{{ time.minute }}</b>
              <b>:</b>
              <b>{{ time.second }} </b>
            </span>
            <span class="time_hide" v-show="!timeShow">
              <strong>Time</strong>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div v-else class="header-default">
      <div class="col-sm-4">
        <Logo />
      </div>
      <div class="col-sm-4">
        <el-button
          type="primary"
          @click="$emit('dook', 'next')"
          :disabled="disableNextButton"
        >
          Next
        </el-button>
      </div>
      <div class="col-sm-4">
        <div class="time">
          <el-button @click="timeShow = !timeShow">
            <b v-show="timeShow">Hide</b>
            <b v-show="!timeShow">Show</b>
          </el-button>
          <el-button type="danger">
            <span class="time_show" v-show="timeShow">
              <b>{{ time.minute }}</b>
              <b>:</b>
              <b>{{ time.second }} </b>
            </span>
            <span class="time_hide" v-show="!timeShow">
              <strong>Time</strong>
            </span>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/views/toefl/toeflTest/components/Logo";

export default {
  name: "MainMenu",
  components: {
    Logo
  },
  props: ["answers", "mode", "time", "disableNextButton"],
  data() {
    return {
      showAnswer: false,
      timeShow: true
    };
  },
  mounted() {},
  watch: {
    answers() {
      this.showAnswer = false;
    }
  },
  methods: {}
};
</script>

<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.your_ansers {
  line-height: 80px;
}
</style>
