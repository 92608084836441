<template>
  <div id="QuestionDefault" class="col-xs-12 col-sm-6 col-md-6 cover">
    <div class="intro_screen question cover" style="opacity: 1;">
      <div class="reading_default">
        <h3 class="q_title" v-html="question_detail.question"></h3>
        <div class="tips">
          Click on {{ this.question_detail.content.checkbox_count }} answers
        </div>
        <el-checkbox-group v-model="theAnswers">
          <el-checkbox
            class="option"
            v-for="option in question_detail.options"
            :label="option.id"
            :key="option.id"
          >
            {{ option.title }}
          </el-checkbox>
        </el-checkbox-group>
        <h5>The corresponding paragraph number is highlighted.</h5>
        <GoogleAd :key="question_detail.id" />
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import _ from "lodash";
import GoogleAd from "@/views/ad/GoogleAd";
export default {
  props:["question_detail", "answers"],
  created(){
  },
  components:{ GoogleAd },
  data() {
    //不能直接修改answer
    return {
      theAnswers: _.cloneDeep(this.answers)
    };
  },
  mounted(){
    this.getAnswer();
  },
  watch: {
    //监听answer进行改变
    answers(value){
      this.theAnswers = _.cloneDeep(value);
    },
    theAnswers(){
      this.getAnswer();
    }
  },
  methods: {
    getAnswer(){
      if (this.theAnswers.length > Number(this.question_detail.content.checkbox_count)) {
        this.theAnswers.pop();
      }
      let answers = _.cloneDeep(this.theAnswers);
      answers.sort(this.cmp);
      this.$parent.setAnswers(answers);
    },
    cmp(a, b){
      return a - b;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.cover {
  height: 100%;
}
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0 15px;
}
.option {
  display: block;
  margin: 40px 0;
  line-height: 22px;
  text-align: left;
}
.option >>> .el-checkbox__label {
  font-size: 16px;
}
.option >>> .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
.option >>> .el-checkbox__inner::after {
  height: 8px;
  width: 4px;
  left: 6px;
  top: 3px;
}
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0;
}
.tips {
  width: 300px;
  text-align: center;
  margin: 20px 80px;
  background-color: #ececec;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0;
}
</style>
