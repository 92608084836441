<template>
  <div
    id="TestIntroduction"
    class="directions"
    style="width: 100%;height: 100%"
  >
    <div v-if="mode == '1'" class="mylogo header-strict">
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <button type="primary" @click="$emit('showintroductionclick')">
          Continue
        </button>
      </div>
    </div>
    <div v-else class="header-default">
      <div class="col-sm-4 "></div>
      <div class="col-sm-4">
        <el-button type="primary" @click="$emit('showintroductionclick')">
          Continue
        </el-button>
      </div>
    </div>

    <div class="directions-text row mar">
      <div class="intro_screen text cover">
        <h2 class="text-center">{{ question_detail.chapter_title }}</h2>
        <p id="passage" :class="{ mock: mode == '1' }"></p>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: ["mode", "question_detail"],
  data() {
    return {};
  },
  watch: {
    question_detail() {
      this.setPassage();
    }
  },
  mounted() {
    this.setPassage();
  },
  methods: {
    setPassage() {
      let passage = document.getElementById("passage");
      if (this.question_detail.chapter_content.html) {
        passage.innerHTML = this.question_detail.chapter_content.html;
        let vm = this;
        $(".paragraph").each(function(item) {
          let questionOrders = $(this).data("light");
          if (questionOrders?.includes(vm.question_detail.question_order)) {
            $(this).addClass("paragraph-anchor");
            $(this)
              .find("b")
              .addClass("light");
          }
        });

        $("[data-light-order]").each(function(item) {
          let questionOrder = $(this).data("light-order");
          if (questionOrder === vm.question_detail.question_order) {
            $(this).addClass("light");
          } else {
            $(this).removeClass("light");
          }
        });
      } else {
        passage.innerHTML = this.question_detail.content.html;
      }
      let dom = document.getElementsByClassName("insert-area");
      for (var i = 0; i < dom.length; i++) {
        if (this.question_detail.type === "click") {
          if (this.mode === "1") {
            dom[i].innerHTML =
              " <b class='text-success text-option'><span style='font-size:28px;line-height: 14px'>■</span></b> ";
          } else {
            dom[i].innerHTML = ` <b class="text-success">[${dom[i].getAttribute(
              "data-answer"
            )}]  <span style="line-height: 14px">■</span></b> `;
          }
        } else {
          dom[i].innerHTML = null;
        }
      }
      let vm = this;
      $(".insert-area .text-success").click(function() {
        $(".insert-option").remove();
        $(this).append(
          " <span class='insert-option'>" +
            vm.question_detail.question +
            "</span>"
        );
        vm.setAnswer(
          $(this)
            .parent()
            .data("answer")
        );
      });
      $(
        ".insert-area[data-answer='" + this.insertTitle + "'] .text-success"
      ).append(
        ` <span class='insert-option'>
        ${this.question_detail.question}
        </span>`
      );
      this.setAnswer(
        $(this)
          .parent()
          .data("answer")
      );
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.mylogo {
  line-height: 80px;
}

::v-deep .article br,
::v-deep .insert-area {
  display: none;
}
.cover {
  height: 100%;
}
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0 15px;
}
.paragraph > b:first-child {
  display: inline-block;
  margin-right: 5px;
}
</style>
