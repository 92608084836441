<template>
  <div>
    <!-- <passage-default
      :question_detail="question_detail"
      v-if="showQuestion == false"
    >
    </passage-default> -->
    <div>
      <div v-if="question_detail.type === 'integrated'">
        <direction
          v-if="page === 0"
          type="integrated"
          :question_detail="question_detail"
          :mode="mode"
        ></direction>
        <article-passage
          v-if="page == 1"
          :mode="mode"
          :question_detail="question_detail"
          :isSkipReadPassage="isSkipReadPassage"
        ></article-passage>
        <audio-passage
          v-if="page == 2"
          :mode="mode"
          :question_detail="question_detail"
        ></audio-passage>
        <div v-if="page == 3">
          <main-menu
            key="integrated"
            :mode="mode"
            seconds="1200"
            :count="count"
            @dook="getQuestion"
            :question_detail="question_detail"
          ></main-menu>
          <div class="single">
            <question-default
              :key="question_detail.chapter_order"
              :question_detail="question_detail"
              prepare_time="30"
              answer_time="60"
              :mode="mode"
              :count="count"
            ></question-default>
          </div>
        </div>
      </div>
      <div v-if="question_detail.type === 'independent'">
        <direction
          v-if="page === 0"
          type="independent"
          :question_detail="question_detail"
          :mode="mode"
        ></direction>
        <div v-if="page == 1">
          <main-menu
            key="independent"
            seconds="1800"
            :mode="mode"
            :count="count"
            @dook="getQuestion"
            :question_detail="question_detail"
          ></main-menu>
          <div class="single">
            <question-default
              :key="question_detail.chapter_order"
              :question_detail="question_detail"
              :mode="mode"
              :count="count"
            ></question-default>
          </div>
        </div>
      </div>
      <div v-if="question_detail.type === 'independent_new'">
        <div v-if="page == 0">
          <main-menu
            key="independent"
            seconds="600"
            :mode="mode"
            :count="count"
            @dook="getQuestion"
            :question_detail="question_detail"
          ></main-menu>
          <div class="single">
            <question-default-new
              :key="question_detail.chapter_order"
              :question_detail="question_detail"
              prepare_time="30"
              answer_time="60"
              :mode="mode"
              :count="count"
            ></question-default-new>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from "./components/MainMenu";
import Direction from "./components/Direction";
import AudioPassage from "./components/AudioPassage";
import ArticlePassage from "./components/ArticlePassage";
import QuestionDefault from "./components/QuestionDefault";
import QuestionDefaultNew from "./components/QuestionDefaultNew";
// import TOEFL from "@/apis/toefl";

export default {
  metaInfo() {
    return {
      title: "Writing - " + this.CompanyName
    };
  },

  components: {
    MainMenu,
    QuestionDefault,
    ArticlePassage,
    AudioPassage,
    Direction,
    QuestionDefaultNew
  },

  mixins: [],

  props: ["question_detail", "questions", "mode", "isSkipReadPassage"],
  data() {
    return {
      showQuestion: false,
      page: 0,
      count: 0
    };
  },
  computed: {},
  watch: {
    question_detail() {
      this.page = 0;
      this.count = 0;
      this.setAnswers([""]);
    }
  },

  created() {
    this.setAnswers([""]);
  },
  methods: {
    getQuestion(value, questionTime) {
      //判断前进还是后退；
      this.$parent.getQuestion(value, 0, questionTime);
    },
    setAnswers(answers) {
      this.count = this.getWordCount(answers[0]);
      this.$emit("setAnswers", answers);
    },
    setPage() {
      this.page++;
    },
    getWordCount(string) {
      let word = 0;
      let count = 0;
      if (string == "") {
        count = 0;
      } else {
        for (let i = 0; i < string.length; i++) {
          if (string[i] == " ") {
            word = 0;
          } else if (word == 0) {
            word = 1;
            count++;
          }
        }
      }
      return count;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
