<template>
  <div style="height:100%">
    <div id="QuestionDefault" class="col-xs-12 col-sm-12 col-md-12 cover">
      <div class="intro_screen question cover">
        <div
          class="title_text directions-style"
          v-if="question_detail.type && question_detail.type == 'integrated'"
        >
          <div class="directions-box">
            Directions: You have 20 minutes to plan and write your response.
            Your response will be judged on the basis of the quality of your
            writing and on how well your response presents the points in the
            lecture and their relationship to the reading passage. Typically, an
            effective response will be 150 to 225 words.
          </div>
          <div class="question-style">
            <b>Question: {{ getQuestion(question_detail.question) }}</b>
          </div>
        </div>
        <div
          class="title_text directions-style"
          v-if="question_detail.type && question_detail.type == 'independent'"
        >
          <div class="directions-box">
            {{ getQuestion(question_detail.question) }}
          </div>
          <div class="question-style">
            <b for="" v-html="question_detail.content.article"></b>
          </div>
        </div>
        <hr />
        <div class="article">
          <div class="col-xs-12 col-md-6 cover article-text">
            <!-- <p class="article-title text-center"><b>Question</b></p> -->
            <p
              class="writing-article"
              v-html="question_detail.content.article"
            ></p>
          </div>
          <div
            class="col-xs-12 col-md-6 cover article-answer"
            v-if="mode == '1'"
            style="display:flex;flex-direction:column"
          >
            <div class="button-group mode-1" style="width:100%;">
              <div>
                <el-button @click="copy">Copy</el-button>
                <el-button @click="cut">Cut</el-button>
                <el-button @click="paste">Paste</el-button>
                <!-- <el-button @click="undo">Undo</el-button>
                <el-button @click="redo">Redo</el-button> -->
              </div>
              <div>
                <el-button>Word Count</el-button>
                <b style="margin: 0 20px"> {{ count }}</b>
              </div>
            </div>
            <div style="flex-grow: 1">
              <el-input
                type="textarea"
                style="width:100%;height:100%"
                placeholder="Please type your response here"
                v-model="theAnswer"
              >
              </el-input>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 cover article-answer" v-else>
            <div class="button-group" style="width:100%;height: 10%">
              <div>
                <el-button @click="copy">Copy</el-button>
                <el-button @click="cut">Cut</el-button>
                <el-button @click="paste">Paste</el-button>
                <!-- <el-button @click="undo">Undo</el-button>
                <el-button @click="redo">Redo</el-button> -->
              </div>
              <div>
                <el-button>Word Count</el-button>
                <b style="margin: 0 20px"> {{ count }}</b>
              </div>
            </div>
            <el-input
              id="answer"
              type="textarea"
              style="width:100%;height: 90%"
              placeholder="Please type your response here"
              v-model="theAnswer"
            >
            </el-input>
          </div>
        </div>
        <GoogleAd :key="question_detail.id" />
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import GoogleAd from "@/views/ad/GoogleAd";
export default {
  props:["question_detail", "answer", "prepare_time", "answer_time", "mode", "count"],
  components:{
    GoogleAd
  },
  data() {
    return {
      showBar: false,
      theAnswer:this.answer,
      totalText: "",
      clipbrd: ""
    };
  },
  watch: {
    answer(){
      this.theAnswer = this.answer;
    },
    theAnswer() {
      this.getAnswer();
    }
  },
  methods: {
    getAnswer(){
      let answers = [];
      answers.push(this.theAnswer);
      this.$parent.setAnswers(answers);
    },
    getQuestion(val) {
      let text = val.replace(
        /Directions: You have 20 minutes to plan and write your response. Your response will be judged on the basis of the quality of your writing and on how well your response presents the points in the lecture and their relationship to the reading passage. Typically, an effective response will be 150 to 225 words./,
        ""
      );
      return text;
    },
    copy(){
      this.clipbrd = this.getSelect();
    },
    cut(){
      let clipbrd = "";
      let textBox = document.getElementById("answer");
      textBox.focus();
      if(textBox.selectionStart >= 0){
        clipbrd = textBox.value.substring(textBox.selectionStart,textBox.selectionEnd);
      }else if(document.selection){
        clipbrd = document.selection.createRange().text;
      }
      this.clipbrd = clipbrd;
      let str = textBox.value.substring(0, textBox.selectionStart) + textBox.value.substring(textBox.selectionEnd,textBox.value.length);
      this.theAnswer = str;
    },
    paste(){
      let obj = document.getElementById("answer");
      let str = this.clipbrd;
      obj.focus();
      if (document.selection) {
        var sel = document.selection.createRange();
        sel.text = str;
      } else if (typeof obj.selectionStart == "number"
          && typeof obj.selectionEnd == "number") {
        var startPos = obj.selectionStart, endPos = obj.selectionEnd, cursorPos = startPos, tmpStr = obj.value;
        obj.value = tmpStr.substring(0, startPos) + str
            + tmpStr.substring(endPos, tmpStr.length);
        cursorPos += str.length;
        obj.selectionStart = obj.selectionEnd = cursorPos;
      } else {
        obj.value += str;
      }
      this.theAnswer = obj.value;
    },
    getSelect(){
      let textBox = document.getElementById("answer");
      let str = "";
      if(textBox.selectionStart >= 0){
        str = textBox.value.substring(textBox.selectionStart,textBox.selectionEnd);
      }else if(document.selection){
        str =  document.selection.createRange().text;
      }
      return str;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.cover {
  height: 100%;
}
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0;
}
.time-container {
  margin: 0 auto;
}
#QuestionDefault >>> .el-textarea textarea {
  height: 100%;
}
.article {
  display: flex;
}
.article-text {
  padding-right: 20px;
}
.directions-box {
  border: 1px solid #cacbcc;
  background-color: #f2f3f5;
  padding: 20px;
}
.question-style {
  margin-top: 20px;
}
.button-group {
  display: flex;
  justify-content: space-between;
}
.mode-1 {
  background: #f2f3f5;
  padding: 10px;
}
::v-deep .mode-1 button {
  background-color: #283773;
  min-width: 80px;
  color: #fff;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  font-weight: bold;
  padding: 0 12px;
  margin: 0 10px;
  border-radius: 3px;
  border: none;
  box-shadow: inset 0 3px 2px -1px #989fbc, inset -3px 0 2px -1px #636e99,
    inset 0 -3px 2px -1px #151d3b, inset 3px 0 2px -1px #1b254d;
}
</style>
