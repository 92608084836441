<template>
  <div>
    <div
      v-if="mode == '1'"
      :class="mode == '1' ? 'mylogo header-strict' : 'header-default'"
    >
      <div class="col-sm-4">
        <Logo />
      </div>
      <div class="col-sm-4">
        <b>{{ question_detail.toefl_title }}</b>
        Passage <b>{{ question_detail.chapter_order }}</b
        >, Question <b>{{ question_detail.question_order }} </b>
      </div>
      <div class="col-sm-4 control">
        <div class="control-button">
          <div class="review"></div>
          <div class="next" v-show="!showReview">
            <button
              class="round-btn"
              type="primary"
              @click="$emit('dook', 'next', questionTime)"
            >
              NEXT
            </button>
          </div>
        </div>
        <div class="time">
          <button @click="timeShow = !timeShow">
            <span v-show="timeShow">Hide</span>
            <span v-show="!timeShow">Show Time</span>
          </button>

          <span class="time_show" v-show="timeShow">
            <b>{{ time.minute }}</b>
            <b>:</b>
            <b>{{ time.second }} </b>
          </span>
        </div>
      </div>
    </div>
    <div v-else :class="mode == '1' ? 'mylogo' : 'header-default'">
      <div class="col-sm-2">
        <Logo />
      </div>
      <div class="col-sm-3">
        <b class="test_name"> Writing (Task {{ question_detail.chapter_order }}) </b>
      </div>
      <div class="col-sm-2">
        <el-button type="danger" title="Show / Hide">
          <span class="time_show">
            <strong id="minute_show">{{ time.minute }}</strong>
            <strong>:</strong>
            <strong id="second_show">{{ time.second }}</strong>
          </span>
          <span class="time_hide" style="display:none;">
            Show Time
          </span>
        </el-button>
      </div>
      <div class="col-sm-3"></div>
      <div class="col-sm-2">
        <el-button type="primary" @click="$emit('dook', 'next', questionTime)">
          Submit
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/views/toefl/toeflTest/components/Logo";

export default {
  components: {
    Logo
  },
  props: ["question_detail", "answers", "seconds", "count", "mode"],
  data() {
    return {
      testTimeInterval: null,
      time: {
        minute: "",
        second: ""
      },
      timeShow: true,
      questionTime: 0,
      questionTimer: null
    };
  },
  watch: {
    question_detail() {
      this.countDown(this.seconds);
      this.questionTime = 0;
    }
  },
  mounted() {
    this.countDown(this.seconds);
    this.questionTime = 0;
    if (this.questionTimer) {
      clearInterval(this.questionTimer);
    }
    this.questionTimer = setInterval(() => {
      this.questionTime++;
    }, 1000);
  },
  destroyed() {
    //销毁各种定时器
    if (this.questionTimer) {
      clearInterval(this.questionTimer);
    }
  },
  methods: {
    countDown(seconds_remaining) {
      let minute = Math.floor(seconds_remaining / 60);
      let second = Math.floor(seconds_remaining) - minute * 60;
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      this.time.minute = minute;
      this.time.second = second;
      this.testTimeInterval = setInterval(() => {
        var minute;
        var second;
        minute = Math.floor(seconds_remaining / 60);
        second = Math.floor(seconds_remaining) - minute * 60;
        // 让个位数的时间正常显示
        if (minute <= 9) minute = "0" + minute;
        if (second <= 9) second = "0" + second;
        this.time.minute = minute;
        this.time.second = second;
        seconds_remaining--;
        if (seconds_remaining < 0) {
          clearInterval(this.testTimeInterval);
          this.$emit("dook", "next", this.questionTime);
        }
      }, 1000);
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.your_ansers {
  line-height: 80px;
}
.header-strict .control {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header-strict .control-button {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.header-strict .control .time {
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.header-default .control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
