var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showQuestion == false)?_c('passage-default',{attrs:{"question_detail":_vm.question_detail,"mode":_vm.mode,"isSkipListening":_vm.isSkipListening}}):_c('div',[_c('div',[(
          _vm.question_detail.type === 'default' &&
            (!_vm.question_detail.content.repeat_audio ||
              _vm.question_detail.content.repeat_audio == '')
        )?_c('div',[_c('main-menu',{attrs:{"mode":_vm.mode,"time":_vm.time,"disableNextButton":_vm.disableNextButton},on:{"dook":_vm.getQuestion}}),_c('question-default',{attrs:{"question_detail":_vm.question_detail,"answers":_vm.answers,"isSkipListening":_vm.isSkipListening},on:{"setAnswers":_vm.setAnswers,"enableNextButton":_vm.enableNextButton}})],1):_vm._e(),(['checkbox', 'listening_match'].includes(_vm.question_detail.type))?_c('div',[_c('main-menu',{attrs:{"mode":_vm.mode,"time":_vm.time,"disableNextButton":_vm.disableNextButton},on:{"dook":_vm.getQuestion}}),_c('question-checkbox',{attrs:{"question_detail":_vm.question_detail,"answers":_vm.answers,"isSkipListening":_vm.isSkipListening},on:{"setAnswers":_vm.setAnswers,"enableNextButton":_vm.enableNextButton,"disableNextButton":function($event){_vm.disableNextButton = true}}})],1):_vm._e(),(_vm.question_detail.type === 'sort')?_c('div',[_c('main-menu',{attrs:{"mode":_vm.mode,"time":_vm.time,"disableNextButton":_vm.disableNextButton},on:{"dook":_vm.getQuestion}}),_c('question-sort',{attrs:{"question_detail":_vm.question_detail,"answers":_vm.answers},on:{"setAnswers":_vm.setAnswers,"enableNextButton":_vm.enableNextButton}})],1):_vm._e(),(_vm.question_detail.type === 'multiple_choice')?_c('div',[_c('main-menu',{attrs:{"mode":_vm.mode,"time":_vm.time,"disableNextButton":_vm.disableNextButton},on:{"dook":_vm.getQuestion}}),_c('question-multiple-choice',{attrs:{"question_detail":_vm.question_detail,"answers":_vm.answers,"isSkipListening":_vm.isSkipListening},on:{"setAnswers":_vm.setAnswers,"enableNextButton":_vm.enableNextButton}})],1):_vm._e(),(
          _vm.question_detail.type === 'default' &&
            _vm.question_detail.content.repeat_audio &&
            _vm.question_detail.content.repeat_audio !== null
        )?_c('div',[(_vm.page === 1)?_c('question-repeat',{attrs:{"mode":_vm.mode,"question_detail":_vm.question_detail,"isSkipListening":_vm.isSkipListening}}):_vm._e(),(_vm.page === 2)?_c('div',[_c('main-menu',{attrs:{"mode":_vm.mode,"time":_vm.time,"disableNextButton":_vm.disableNextButton},on:{"dook":_vm.getQuestion}}),_c('question-default',{attrs:{"question_detail":_vm.question_detail,"answers":_vm.answers,"isSkipListening":_vm.isSkipListening},on:{"setAnswers":_vm.setAnswers,"enableNextButton":_vm.enableNextButton}})],1):_vm._e()],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }