<template>
  <div class="listening-passage col-xs-12 col-sm-12 col-md-12">
    <div class="intro_screen question cover" style="opacity: 1;">
      <p class="q_count text-center">
        Question<b> {{ question_detail.question_order }} </b>
      </p>
      <audio
        :src="question_detail.content.question_audio"
        @ended="stopPlay"
        @error="stopPlay"
        autoplay
      ></audio>
      <h3 class="q_title">
        <label v-html="question_detail.question"></label>
        <span
          class="headphones"
          v-if="question_detail.content.repeat_audio && question_detail.content.repeat_audio !== ''"
        >
          <img
            src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/earPhone.png"
            alt=""
          />
        </span>
      </h3>
      <div class="options" v-show="showOptions">
        <div
          class="radio"
          v-for="option in question_detail.options"
          :key="option.id"
        >
          <div>
            <input
              class="magic-radio"
              :value="option.id"
              v-model="theAnswer"
              type="radio"
              :id="option.id"
            />
            <label :for="option.id">{{ option.title }}</label>
          </div>
        </div>
      </div>
      <GoogleAd :key="question_detail.id" />
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import GoogleAd from "@/views/ad/GoogleAd";

export default {
  props:["question_detail", "answers", "isSkipListening"],
  created(){
  },
  components: {
    GoogleAd
  },
  data() {
    return {
      theAnswer: this.answers[0],
      showOptions: false
    };
  },
  mounted() {
    this.getAnswer();
    this.setDefaultShowOptions();
  },
  watch: {
    "question_detail.id"(){
      this.setDefaultShowOptions();
    },
    answers(value){
      this.theAnswer = value[0];
    },
    theAnswer() {
      this.getAnswer();
    },
    isSkipListening() {
      this.stopPlay();
    }
  },
  methods: {
    stopPlay() {
      this.showOptions = true;
      this.$emit("enableNextButton");
    },
    setDefaultShowOptions() {
      if (this.question_detail.content.question_audio === "" || this.isSkipListening) {
        this.stopPlay();
      } else {
        this.showOptions = false;
      }
    },
    getAnswer(){
      let answers = [];
      answers.push(this.theAnswer);
      answers.sort(this.cmp);
      this.$parent.setAnswers(answers);
    },
    cmp(a, b){
      return a - b;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.cover {
  height: 100%;
}
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0;
}

.headphones img {
  margin-left: 30px;
  width: 50px;
}
</style>
