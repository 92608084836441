<template>
  <div class="intro_screen cover">
    <div class="col-md-12">
      <p class="q_count text-center">
        Question<b> {{ question_detail.question_order }} </b>
      </p>
      <div class="init_title text">
        <p class="title-p">
          <b>Directions</b>: An introductory sentence for a brief summary of
          the passage is provided below. Complete the summary by selecting the
          THREE answer choices that express the most important ideas in the
          passage. Some sentences do not belong in the summary because they
          express ideas that are not presented in the passage or are minor
          ideas in the passage. <b>This question is worth 2 points.</b>
        </p>
        <el-alert type="success" center :closable="false">
          <h5 style="margin:10px 0">
            Drag your answer choices to the spaces where they belong. To
            review the passage, click on <b>VIEW TEXT</b>.
          </h5>
        </el-alert>
        <h5 class="text-center" style="margin-top:20px">
          <b>{{ getQuestion(question_detail.question) }}</b>
        </h5>
      </div>
      <div class="row">
        <div class="col-sm-3"></div>
        <div class="col-sm-6">
          <div class="select-area">
            <ul class="three-points">
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <draggable
              class="list-group row"
              :list="myAnswers"
              group="people"
              @change="getAnswer"
            >
              <div
                class="list-item"
                v-for="(element, index) in myAnswers"
                @click="restOption(index)"
                :key="index"
              >
                {{ element.title }}
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <div class="">
        <h5 class="text-center">
          <b>Answer Choices</b>
        </h5>
        <draggable class="row" :list="options" group="people">
          <div
            @click="alert"
            class="col-sm-6"
            v-for="(element, index) in options"
            :key="index"
          >
            <div class="list-item ">
              {{ element.title }}
            </div>
          </div>
        </draggable>
        <GoogleAd :key="question_detail.id" />
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import _ from "lodash";
import GoogleAd from "@/views/ad/GoogleAd";

export default {
  components: {
    draggable,
    GoogleAd
  },
  props: ["question_detail", "answers"],
  data() {
    return {
      options: _.cloneDeep(this.question_detail.options),
      myAnswers: []
    };
  },
  mounted() {
    // this.getAnswer();
    let options = [];
    let myAnswers = [];
    this.question_detail.options.forEach(option => {
      let index = this.answers.indexOf(option.id);
      if (index > -1) {
        myAnswers.push(option);
      } else {
        options.push(option);
      }
    });
    this.options = options;
    this.myAnswers = myAnswers;
    // this.getAnswer();
  },
  watch: {},
  methods: {
    alert() {
      this.$message({
        message:
          "Please click and DRAG your answer choices into the space above.",
        type: "warning"
      });
    },
    restOption(index) {
      this.options.push(this.myAnswers[index]);
      this.myAnswers.splice(index, 1);
      this.options = _.sortBy(this.options, ["number"]);
      this.getAnswer();
    },
    getQuestion(val) {
      let text = val.replace(
        /Directions: An introductory sentence for a brief summary of the passage is provided below. Complete the summary by selecting the THREE answer choices that express the most important ideas in the passage. Some sentences do not belong in the summary because they express ideas that are not presented in the passage or are minor ideas in the passage. This question is worth 2 points./,
        ""
      );
      return text;
    },
    getAnswer() {
      if (this.myAnswers.length > 3) {
        let item = this.myAnswers.pop();
        this.options.push(item);
      }
      let answers = [];
      this.myAnswers.forEach(option => {
        answers.push(option.id);
      });
      answers.sort(this.cmp);
      console.log(answers);
      this.$parent.setAnswers(answers);
    },
    cmp(a, b) {
      return a - b;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.el-alert--success.is-light {
  background-color: #f2f3f5;
}

.list-group {
  width: 100%;
  min-height: 352px;
  /* max-height: 400px; */
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 10px 20px;
}
.list-item {
  cursor: pointer;
  min-height: 90px;
  border: 1px solid #cacbcc;
  background-color: #f2f3f5;
  padding: 20px 10px;
  margin: 10px 0;
}
.select-area {
  position: relative;
}
.select-area .three-points {
  position: absolute;
  left: 7px;
}
.select-area .three-points li {
  color: var(--themeColor);
  height: 110px;
  line-height: 110px;
}
</style>
