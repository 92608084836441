<template>
  <div class="listening-passage col-xs-12 col-sm-12 col-md-12">
    <div class="intro_screen question cover" style="opacity: 1;">
      <p class="q_count text-center">
        Question<b> {{ question_detail.question_order }} </b>
      </p>
      <audio
        :src="question_detail.content.question_audio"
        @ended="stopPlay"
        @error="stopPlay"
        autoplay
      ></audio>
      <h3 class="q_title" v-html="question_detail.question"></h3>

      <div class="sentence">
        <h5>Click in the correct box for each phrase.</h5>
        This item is worth 2 point.
      </div>
      <div class="options" v-show="showOptions">
        <table class="table table-bordered">
          <tbody>
            <tr
              v-for="(option, index) in question_detail.content.options"
              :key="index"
            >
              <td style="vertical-align: middle;">{{ option }}</td>
              <td v-for="(i, iIndex) in lines" :key="iIndex">
                <div class="radio">
                  <div>
                    <input
                      class="magic-radio"
                      :value="
                        question_detail.options[index * lines + iIndex].id
                      "
                      v-model="theAnswers[index]"
                      type="radio"
                      :id="question_detail.options[index * lines + iIndex].id"
                    />
                    <label
                      :for="question_detail.options[index * lines + iIndex].id"
                      >{{
                        question_detail.options[index * lines + iIndex].title
                      }}</label
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <GoogleAd :key="question_detail.id" />
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import _ from "lodash";
import GoogleAd from "@/views/ad/GoogleAd";
export default {
  props:["question_detail", "answers", "isSkipListening"],
  components: {
    GoogleAd
  },
  computed:{
    lines(){
      let lines = 2;
      lines = this.question_detail.options.length / this.question_detail.content.options.length;
      return lines;
    }
  },
  data() {
    return {
      theAnswers: _.cloneDeep(this.answers),
      showOptions: false
    };
  },
  mounted() {
    this.getAnswer();
    this.setDefaultShowOptions();
  },
  watch: {
    "question_detail.id"(){
      this.setDefaultShowOptions();
    },
    answers(value){
      this.theAnswers = _.cloneDeep(value);
    },
    theAnswers(){
      this.getAnswer();
    },
    isSkipListening() {
      this.stopPlay();
    }
  },
  methods: {
    stopPlay() {
      this.showOptions = true;
      this.$emit("enableNextButton");
    },
    setDefaultShowOptions() {
      if (this.question_detail.content.question_audio === "" || this.isSkipListening) {
        this.stopPlay();
      } else {
        this.showOptions = false;
      }
    },
    getAnswer(){
      let answers = _.cloneDeep(this.theAnswers);
      answers.sort(this.cmp);
      this.$parent.setAnswers(answers);
    },
    cmp(a, b){
      return a - b;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.cover {
  height: 100%;
}
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0;
}
.sentence {
  text-align: center;
  border: 1px solid #cacbcc;
  background-color: #f2f3f5;
  padding: 10px;
  margin: 20px 5px;
}
</style>
