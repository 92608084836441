<template>
  <div>
    <div v-if="mode == '1'" class="mylogo header-strict">
      <div class="col-sm-4">
        <Logo />
      </div>
      <div class="col-sm-4">
        <b>{{ question_detail.toefl_title }}</b>
        Passage <b>{{ question_detail.chapter_order }}</b
        >, Question <b>{{ question_detail.question_order }} </b>
      </div>
      <div class="col-sm-4 control">
        <div class="control-button">
          <div
            class="review"
            v-if="
              question_detail.type == 'multiple' ||
                question_detail.type == 'double'
            "
          >
            <button
              class="viewtext"
              type="primary"
              @click="$emit('showViewText')"
            >
              <span class="review_text" v-show="!showViewText">
                [<span style="font-size:16px">
                  <i class="fas fa-newspaper"></i> </span
                >]
                <span style="line-height:40px"> VIEW TEXT</span>
              </span>
              <span class="return_text" v-show="showViewText">
                <span style="line-height:40px">VIEW QUESTION</span>
              </span>
            </button>
          </div>
          <div class="review">
            <button
              class="round-btn"
              type="primary"
              @click="$emit('showreview')"
            >
              <span class="review_text" v-show="!showReview">REVIEW</span>
              <span class="return_text" v-show="showReview">RETURN</span>
            </button>
          </div>
          <div class="back" v-show="!showReview">
            <button
              class="round-btn"
              type="primary"
              @click="$emit('doback', 'back')"
            >
              BACK
            </button>
          </div>
          <div class="next" v-show="!showReview">
            <button
              class="round-btn"
              type="primary"
              @click="$emit('donext', 'next')"
            >
              NEXT
            </button>
          </div>
        </div>
        <div class="time">
          <button @click="timeShow = !timeShow">
            <span v-show="timeShow">Hide</span>
            <span v-show="!timeShow">Show Time</span>
          </button>

          <span class="time_show" v-show="timeShow">
            <b>{{ time.minute }}</b>
            <b>:</b>
            <b>{{ time.second }} </b>
          </span>
        </div>
      </div>
    </div>
    <div v-else class="header-default">
      <div class="col-sm-3">
        <Logo />
      </div>
      <div class="col-sm-3">
        <b>{{ question_detail.toefl_title }}</b>
        Passage <b>{{ question_detail.chapter_order }}</b
        >, Question <b>{{ question_detail.question_order }} </b>
      </div>
      <div class="col-sm-6 control">
        <div
          class="review"
          v-if="
            question_detail.type == 'multiple' ||
              question_detail.type == 'double'
          "
        >
          <el-button type="primary" @click="$emit('showViewText')">
            <span class="review_text" v-show="!showViewText">ViewText</span>
            <span class="return_text" v-show="showViewText">VIEW QUESTION</span>
          </el-button>
        </div>
        <div class="review">
          <el-button type="primary" @click="$emit('showreview')">
            <span class="review_text" v-show="!showReview">REVIEW</span>
            <span class="return_text" v-show="showReview">RETURN</span>
          </el-button>
        </div>
        <div class="back" v-show="!showReview">
          <el-button type="primary" @click="$emit('doback', 'back')">
            BACK
          </el-button>
        </div>
        <div class="next" v-show="!showReview">
          <el-button type="primary" @click="$emit('donext', 'next')">
            NEXT
          </el-button>
        </div>
        <div class="time">
          <el-button @click="timeShow = !timeShow">
            <b v-show="timeShow">Hide</b>
            <b v-show="!timeShow">Show</b>
          </el-button>
          <el-button type="danger">
            <span class="time_show" v-show="timeShow">
              <b>{{ time.minute }}</b>
              <b>:</b>
              <b>{{ time.second }} </b>
            </span>
            <span class="time_hide" v-show="!timeShow">
              <strong>Time</strong>
            </span>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/views/toefl/toeflTest/components/Logo";

export default {
  props: ["showViewText", "showReview", "time", "question_detail", "mode"],
  components: {
    Logo
  },
  methods: {},
  data() {
    return {
      timeShow: true
    };
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.header-strict .control {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header-strict .control-button {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.header-strict .control .time {
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.header-default .control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
