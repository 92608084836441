<template>
  <div style="">
    <div :class="mode == '1' ? 'mylogo' : 'header-default'">
      <div class="col-sm-4">
        <Logo />
      </div>
      <div class="col-sm-4">
        <b class="test_name"> Writing (Task {{ question_detail.chapter_order }}) </b>
      </div>
      <div class="col-sm-4 control">
        <div class="time" v-if="mode == 1">
          <span class="time_show">
            <b>{{ time.minute }}</b>
            <b>:</b>
            <b>{{ time.second }} </b>
          </span>
        </div>
        <div class="time" v-if="mode == 0">
          <el-button type="danger">
            <span class="time_show">
              <b>{{ time.minute }}</b>
              <b>:</b>
              <b>{{ time.second }} </b>
            </span>
          </el-button>
        </div>
      </div>
    </div>

    <div class="listening-passage row mar">
      <div
        class="intro_screen cover"
        v-html="question_detail.content.article"
      ></div>
    </div>
  </div>
</template>

<script>
import Logo from "@/views/toefl/toeflTest/components/Logo";

export default {
  components: {
    Logo
  },

  mixins: [],

  props: ["question_detail", "mode", "isSkipReadPassage"],
  data() {
    return {
      imgUrl:
        "https://ivy-way.s3-ap-northeast-1.amazonaws.com/toefl/img/headset.jpeg",
      showPlay: true,
      time: {
        minute: "03",
        second: "00"
      }
    };
  },
  computed: {},
  watch: {
    isSkipReadPassage() {
      if (this.isSkipReadPassage) {
        this.$parent.setPage();
      }
    }
  },

  mounted() {
    this.countDown(180);
  },

  methods: {
    setPage() {
      this.$parent.setPage();
    },
    countDown(seconds_remaining) {
      this.timer = setInterval(() => {
        var minute;
        var second;
        seconds_remaining--;
        minute = Math.floor(seconds_remaining / 60);
        second = Math.floor(seconds_remaining) - minute * 60;
        // 让个位数的时间正常显示
        if (minute <= 9) minute = "0" + minute;
        if (second <= 9) second = "0" + second;
        this.time.minute = minute;
        this.time.second = second;
        if (seconds_remaining == 0) {
          this.$parent.setPage();
        }
      }, 1000);
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.intro_screen {
  width: 100%;
}
.time-container {
  margin: 0 auto;
}
.intro_screen >>> .article {
  height: 100%;
  overflow: auto;
}
.header-default .control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mylogo .control {
  line-height: 35px;
  display: flex;
  justify-content: flex-end;
}
.mylogo .control .time {
  align-items: center;
  display: flex;
}

</style>
