import { render, staticRenderFns } from "./Test.vue?vue&type=template&id=17077aaa&scoped=true&"
import script from "./Test.vue?vue&type=script&lang=js&"
export * from "./Test.vue?vue&type=script&lang=js&"
import style0 from "@/views/toefl/toeflTest/style/tpo.css?vue&type=style&index=0&id=17077aaa&scoped=true&lang=css&"
import style1 from "./Test.vue?vue&type=style&index=1&id=17077aaa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17077aaa",
  null
  
)

export default component.exports