<template>
  <div>
    <passage-default
      :question_detail="question_detail"
      :mode="mode"
      :isSkipListening="isSkipListening"
      v-if="showQuestion == false"
    >
    </passage-default>
    <div v-else>
      <div>
        <div
          v-if="
            question_detail.type === 'default' &&
              (!question_detail.content.repeat_audio ||
                question_detail.content.repeat_audio == '')
          "
        >
          <main-menu
            :mode="mode"
            @dook="getQuestion"
            :time="time"
            :disableNextButton="disableNextButton"
          />
          <question-default
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            @enableNextButton="enableNextButton"
            :answers="answers"
            :isSkipListening="isSkipListening"
          ></question-default>
        </div>
        <div
          v-if="['checkbox', 'listening_match'].includes(question_detail.type)"
        >
          <main-menu
            :mode="mode"
            @dook="getQuestion"
            :time="time"
            :disableNextButton="disableNextButton"
          />
          <question-checkbox
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            @enableNextButton="enableNextButton"
            @disableNextButton="disableNextButton = true"
            :answers="answers"
            :isSkipListening="isSkipListening"
          ></question-checkbox>
        </div>
        <div v-if="question_detail.type === 'sort'">
          <main-menu
            :mode="mode"
            @dook="getQuestion"
            :time="time"
            :disableNextButton="disableNextButton"
          />
          <question-sort
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            @enableNextButton="enableNextButton"
            :answers="answers"
          ></question-sort>
        </div>
        <div v-if="question_detail.type === 'multiple_choice'">
          <main-menu
            :mode="mode"
            @dook="getQuestion"
            :time="time"
            :disableNextButton="disableNextButton"
          />
          <question-multiple-choice
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            @enableNextButton="enableNextButton"
            :answers="answers"
            :isSkipListening="isSkipListening"
          ></question-multiple-choice>
        </div>
        <div
          v-if="
            question_detail.type === 'default' &&
              question_detail.content.repeat_audio &&
              question_detail.content.repeat_audio !== null
          "
        >
          <question-repeat
            v-if="page === 1"
            :mode="mode"
            :question_detail="question_detail"
            :isSkipListening="isSkipListening"
          >
          </question-repeat>
          <div v-if="page === 2">
            <main-menu
              :mode="mode"
              @dook="getQuestion"
              :time="time"
              :disableNextButton="disableNextButton"
            ></main-menu>
            <question-default
              :question_detail="question_detail"
              @setAnswers="setAnswers"
              @enableNextButton="enableNextButton"
              :answers="answers"
              :isSkipListening="isSkipListening"
            ></question-default>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from "./components/MainMenu";
import PassageDefault from "./components/PassageDefault";
import QuestionDefault from "./components/QuestionDefault";
import QuestionCheckbox from "./components/QuestionCheckbox";
import QuestionSort from "./components/QuestionSort";
import QuestionRepeat from "./components/QuestionRepeat";
import QuestionMultipleChoice from "./components/QuestionMultipleChoice";
// import TOEFL from "@/apis/toefl";

export default {
  metaInfo() {
    return {
      title: "Listening - " + this.CompanyName
    };
  },

  components: {
    MainMenu,
    PassageDefault,
    QuestionDefault,
    QuestionCheckbox,
    QuestionRepeat,
    QuestionSort,
    QuestionMultipleChoice
  },

  mixins: [],

  props: ["question_detail", "answers", "mode", "isSkipListening"],
  data() {
    return {
      disableNextButton: true,
      showQuestion: false,
      page: 1,
      time: {
        minute: "40",
        second: "00"
      },
      timer: null,
      questionTime: 0,
      questionTimer: null
    };
  },
  computed: {
    progress_remaining() {
      return parseInt(this.time.minute) * 60 + parseInt(this.time.second);
    }
  },
  watch: {
    isSkipListening() {
      if (this.isSkipListening) {
        this.enableNextButton();
      }
    },
    page() {
      this.questionTime = 0;
    },
    question_detail() {
      this.questionTime = 0;
      this.page = 1;
      if (this.question_detail.question_order == 1) {
        this.showQuestion = false;
      } else {
        this.showQuestion = true;
      }
    }
  },

  mounted() {
    this.countDown(this.progress_remaining);
    this.questionTime = 0;
    if (this.questionTimer) {
      clearInterval(this.questionTimer);
    }
    this.questionTimer = setInterval(() => {
      this.questionTime++;
    }, 1000);
  },
  destroyed() {
    //销毁各种定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.questionTimer) {
      clearInterval(this.questionTimer);
    }
  },
  methods: {
    enableNextButton() {
      this.disableNextButton = false;
    },
    hiddenPassage() {
      this.showQuestion = true;
      this.questionTime = 0;
    },
    countDown(seconds_remaining, test_id) {
      this.timer = setInterval(() => {
        var minute;
        var second;
        minute = Math.floor(seconds_remaining / 60);
        second = Math.floor(seconds_remaining) - minute * 60;
        // 让个位数的时间正常显示
        if (minute <= 9) minute = "0" + minute;
        if (second <= 9) second = "0" + second;

        this.time.minute = minute;
        this.time.second = second;

        seconds_remaining--;
        if (seconds_remaining == 0) {
          if (this.timer) {
            clearInterval(this.timer);
          }
        }
      }, 1000);
    },
    getQuestion(value) {
      if (this.isSkipListening) {
        this.disableNextButton = false;
      } else {
        this.disableNextButton = true;
      }
      //判断前进还是后退；
      this.$parent.getQuestion(
        value,
        this.progress_remaining,
        this.questionTime
      );
      this.questionTime = 0;
      if (this.questionTimer) {
        clearInterval(this.questionTimer);
      }
      this.questionTimer = setInterval(() => {
        this.questionTime++;
      }, 1000);
    },
    setAnswers(answers) {
      this.$emit("setAnswers", answers);
    },
    setPage() {
      this.page++;
    }
  }
};
</script>

<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
