<template>
  <router-link class="navbar-brand" :to="{ name: 'LandingPage' }">
    <div class="logo">
      <h2>
        {{ theme.name }}
      </h2>
    </div>
  </router-link>
</template>

<script>
import Theme from "@/common/theme";
export default {
  name: "Logo",
  computed: {

    theme() {
      return Theme;
    }
  }
};
</script>

<style src="@/views/toefl/toeflTest/style/tpo.css" scoped />
